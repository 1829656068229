<script>
import checkbox from './checkbox'
export default {
  extends: checkbox,
  methods: {
    setClick (value) {
      this.clicked = [value]
      window.console.log(this.clicked)
    }
  }
}
</script>

<template>
  <div class="container">
    <h4>
      Vorschau der Termine für
      {{ $t("dates.weekdays")[$store.state.business.weekday] }}
    </h4>
    <ul>
      <li
        v-for="date in getDates()"
        :key="date[0]"
        :class="{ error: !date[2] }"
      >
        <icon id="check" v-if="date[2]" />
        <icon id="lock" v-else />
        {{ date[0] }} <icon id="minus" /> {{ date[1] }}
        <span v-if="!date[2]">(Pause)</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      dates: this.getDates()
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getDates() {
      var weekday = this.$store.state.business.weekday;
      var includeWeekday = this.$store.state.business.unsaved.include.weekdays[
        weekday
      ];
      var excludeWeekday = false;
      if (
        typeof this.$store.state.business.unsaved.exclude.weekdays[weekday] !==
        "undefined"
      ) {
        excludeWeekday = this.$store.state.business.unsaved.exclude.weekdays[
          weekday
        ];
      }
      var nextAppointments = this.$store.state.business.unsaved
        .next_appointments[weekday];
      var r = [];
      var start = new Date();
      start.setHours(includeWeekday[0].split(":")[0]);
      start.setMinutes(includeWeekday[0].split(":")[1]);
      start.setSeconds(0);
      start.setMilliseconds(0);

      var end = new Date();
      end.setHours(includeWeekday[1].split(":")[0]);
      end.setMinutes(includeWeekday[1].split(":")[1]);
      end.setSeconds(0);
      end.setMilliseconds(0);

      var steps = (end.getTime() - start.getTime()) / 1000 / 60;
      end = new Date(start);
      end.setMinutes(end.getMinutes() + includeWeekday[2] * 1);

      for (var i = 0; i < steps; i += nextAppointments * 1) {
        r.push([
          this.getNullsBefore(start.getHours()) +
            ":" +
            this.getNullsBefore(start.getMinutes()),
          this.getNullsBefore(end.getHours()) +
            ":" +
            this.getNullsBefore(end.getMinutes()),
          this.checkPause(excludeWeekday, start, end)
        ]);
        start.setMinutes(start.getMinutes() + nextAppointments * 1);
        end.setMinutes(end.getMinutes() + nextAppointments * 1);
      }

      return r;
    },
    checkPause(weekday, checkStart, checkEnd) {
      if (!weekday) {
        return true;
      }

      var success = true;
      weekday.forEach(function(pause) {
        if (!success) {
          return success;
        }
        var start = new Date();
        start.setHours(pause[0].split(":")[0]);
        start.setMinutes(pause[0].split(":")[1] * 1 + 1);
        start.setSeconds(0);
        start.setMilliseconds(0);
        var end = new Date();
        end.setHours(pause[1].split(":")[0]);
        end.setMinutes(pause[1].split(":")[1] * 1 - 1);
        end.setSeconds(0);
        end.setMilliseconds(0);

        if (
          checkStart.getTime() <= end.getTime() &&
          checkEnd.getTime() >= start.getTime()
        ) {
          success = false;
        }
      });

      return success;
    },
    getNullsBefore(index) {
      if (index * 1 < 10) {
        return "0" + index;
      } else {
        return index + "";
      }
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'
.container
  background-color: $light-grey
  padding: 10px
  box-sizing: border-box
  margin: 0
  margin-top: 20px
  border-radius: 5px
  h4
    margin: 0
    margin-bottom: 20px
ul
  margin: 0
  list-style: none
  padding: 0
  display: flex
  flex-direction: row
  flex-wrap: wrap
  align-items: center
  justify-content: center
  li
    background-color: white
    border: 1px solid black
    text-align: center
    flex: 1 20%
    margin: 10px
    margin-left: 0
    margin-top: 0
    color: $success
    border-radius: 5px
    box-shadow: 0 0px 7px -2px rgba(0,0,0,0.4)
    &.error
      color: $danger
</style>

<template>
  <span class="required" v-if="data.required">*</span>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object
  },
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  .required
    color: $danger
    font-weight: bold
</style>

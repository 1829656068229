export default {
  namespaced: true,
  state: {
    edit: false
  },
  getters: {},
  actions: {},
  mutations: {
    setEdit (store, id) {
      store.edit = id
    }
  }
};

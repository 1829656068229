<template>
  <div class="datepicker">
    <div class="picker">
      <icon class="icon" id="calendar" />
      <select v-model="date[2]" @change.prevent="setDate()">
        <option
          v-for="day in Array.from(Array(31).keys())"
          :key="day"
          :value="getNullsBefore(day + 1)"
          >{{ day + 1 }}</option
        >
      </select>
      <select v-model="date[1]" @change.prevent="setDate()">
        <option
          v-for="(month, index) in months"
          :key="index"
          :value="getNullsBefore(index + 1)"
          >{{ month }}</option
        >
      </select>
      <select v-model="date[0]" @change.prevent="setDate()">
        <option v-for="year in years" :key="year">{{ year }}</option>
      </select>
    </div>

    <timePicker
      :time="hours"
      :callback="setHours"
    />
  </div>
</template>
<script>
export default {
  components: {
    timePicker() {
      return import("@/components/default/form/timePicker");
    }
  },
  props: {
    time: String,
    callback: Function
  },
  data() {
    return {
      years: this.getYear(),
      months: this.$t("dates.months"),
      date: this.time.split(" ")[0].split("-"),
      hours: this.time.split(" ")[1]
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setDate() {
      var date =
        this.date[0] +
        "-" +
        this.getNullsBefore(this.date[1]) +
        "-" +
        this.getNullsBefore(this.date[2]) +
        " " +
        this.hours;
      this.callback(date);
    },
    setHours(h) {
      var date = this.time.split(" ")[0] + " " + h;

      this.callback(date);
    },
    getYear() {
      var a = new Date().getFullYear();
      var b = a + 1;

      return [a, b];
    },
    getNullsBefore(i) {
      i = i * 1;
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
  }
};
</script>
<style lang="sass" scoped>
.datepicker
  display: flex
  flex-direction: row
  .picker
    display: inline-flex
    align-items: center
    justify-content: center
    .icon
      font-size: 16pt
    select
      margin: 0 2px
      border: 1px solid grey
      outline: none
      background-color: white
@media (max-width: 500px)
  .datepicker
    flex-direction: column
</style>

<template>
  <div>
    <h4>
      Wie viele Kunden-Termine können am
      {{ $t("dates.weekdays")[$store.state.business.weekday] }} maximal zur
      selben Zeit stattfinden?
    </h4>
    <div class="range">
      <a href="#" @click.prevent="changeAppointment(-1)" class="btn btn-icon">
        <icon id="user-minus" />
      </a>
      <input
        class="btn"
        v-model="maxApplicants"
        type="number"
        step="1"
        max="20"
      />
      <a href="#" @click.prevent="changeAppointment(1)" class="btn btn-icon">
        <icon id="user-plus" />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      maxApplicants: this.getDefaultApplicants(),
      maxValue: 20
    };
  },
  computed: {},
  mounted() {},
  watch: {
    maxApplicants() {
      this.checkmaxApplicants();
    }
  },
  methods: {
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.max_applicants[
        this.$store.state.business.weekday
      ] = this.maxApplicants;
      this.$store.commit("business/prefetch", data);
    },
    getDefaultApplicants() {
      if (
        typeof this.$store.state.business.unsaved.max_applicants[
          this.$store.state.business.weekday
        ] !== "undefined"
      ) {
        return this.$store.state.business.unsaved.max_applicants[
          this.$store.state.business.weekday
        ];
      }
      return 1;
    },
    changeAppointment(add) {
      this.maxApplicants = this.maxApplicants + add;
    },
    checkmaxApplicants() {
      if (this.maxApplicants <= 0) {
        this.maxApplicants = 1;
      }
      if (this.maxApplicants > this.maxValue) {
        this.maxApplicants = this.maxValue;
      }
      this.setSave();
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'
@import '@/assets/sass/range'
</style>

<template>
  <div>
    <input type="text" v-model="data.label">
    <ul>
      <li v-for="h in hArray" :key="h">
        <a href="#" class="btn" @click.prevent="setH(h)" :class="{'btn-invert': data.subtype === 'h'+h}"><icon id="text" style="font-weight: bold"/> | {{ Math.round((7 - h) / 6 * 100) }}%</a>
      </li>
    </ul>
    <columnWidth />
  </div>
</template>
<script>
export default {
  components: {
    columnWidth () {
      return import('./width.vue')
    }
  },
  props: {},
  data () {
    return {
      data: JSON.parse(JSON.stringify(this.$store.state.business.unsaved.form[this.$store.state.form.edit])),
      hArray: [1,2,3,4,5,6]
    }
  },
  watch: {
    'data.label' () { this.setSave() }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    setH (h) {
      this.data.subtype = 'h'+h
      this.setSave()
    },
    setSave () {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.form[this.$store.state.form.edit] = this.data;
      this.$store.commit("business/prefetch", data);
    }
  }
}
</script>
<style lang="sass" scoped>
    @import '@/assets/sass/variables.sass'
    input
      width: 100%
      padding: 10px
      box-sizing: border-box
      font-size: 16pt
      border: 1px solid $success
      font-weight: bold
      &:focus
        outline: none
    ul
      margin: 0
      padding: 0
      list-style: none
      display: flex
      margin-top: 10px
      flex-wrap: wrap
      margin-left: -5px
      margin-right: -5px
      li
        margin: 5px
        a.btn
          font-size: 10pt
          padding: 5px
          height: 25px
</style>

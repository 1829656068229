<template>
  <div>
    <h4>Button-Text der Buchungseinladung</h4>
    <inputText
      :data="btnTxt"
      label=""
      :callback="setTitle"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      btnTxt: this.$store.state.business.unsaved.startBtn
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setTitle(val) {
      this.btnTxt = val;
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.startBtn = this.btnTxt;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>

import { render, staticRenderFns } from "./weekday.vue?vue&type=template&id=683fbf71&scoped=true&"
import script from "./weekday.vue?vue&type=script&lang=js&"
export * from "./weekday.vue?vue&type=script&lang=js&"
import style0 from "./weekday.vue?vue&type=style&index=0&id=683fbf71&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683fbf71",
  null
  
)

export default component.exports
<script>
import def from '@/components/business/settings/lastMinute'
export default {
  extends: def,
  methods: {
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved));
      data.exclude.lastminute = this.lastminute;
      this.$store.commit("privateEvent/prefetch", data);
    },
    getDefaultDuration() {
      if (!this.$store.state.privateEvent.unsaved.exclude.lastminute) {
        return this.default;
      }
      return this.$store.state.privateEvent.unsaved.exclude.lastminute;
    }
  }
}
</script>

<template>
  <span class="error" v-if="!checkDate($store.state.privateEvent.unsaved.events[this.index])">
    <icon id="square-alert" /> Bitte Termindatum überprüfen
  </span>
</template>
<script>
import def from '@/components/business/time/eventsCheck'
export default {
  extends: def
}
</script>

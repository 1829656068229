<template>
  <span class="picker">
    <icon id="clock" class="icon" />
    <select v-model="date[0]" @change.prevent="changeTime">
      <option
        v-for="(index, hour) in new Array(24)"
        :value="hour"
        :key="hour"
        >{{ getNullsBefore(hour) }}</option
      >
    </select>
    :
    <select v-model="date[1]" @change.prevent="changeTime">
      <option
        v-for="(index, minute) in new Array(4)"
        :value="minute * 15"
        :key="minute"
        >{{ getNullsBefore(minute * 15) }}</option
      >
    </select>
  </span>
</template>
<script>
export default {
  components: {},
  props: {
    time: String,
    callback: Function
  },
  data() {
    return {
      date: this.getDate(this.time)
    };
  },
  computed: {},
  mounted() {},
  methods: {
    changeTime() {
      this.callback(
        this.getNullsBefore(this.date[0]) +
          ":" +
          this.getNullsBefore(this.date[1])
      );
    },
    getDate(time) {
      time = time.split(":");
      time[0] = time[0] * 1;
      time[1] = time[1] * 1;
      return time;
    },
    getNullsBefore(i) {
      i = i * 1;
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
  }
};
</script>
<style lang="sass" scoped>
.picker
  display: inline-flex
  align-items: center
  justify-content: center
  .icon
    font-size: 16pt
  select
    margin: 0 2px
    border: 1px solid grey
    outline: none
    background-color: white
</style>

<template>
  <div>
    <calendarPreview />
  </div>
</template>
<script>
export default {
  components: {
    calendarPreview () { return import('@/components/business/preview') }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>

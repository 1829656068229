<template>
  <div>
    <getWeekday :key="$store.state.business.weekday + 0" />
    <div
      v-if="$store.state.business.weekday"
      :key="$store.state.business.weekday"
    >
      <maxApplicants />
      <appointmentDuration />
      <appointmentNext />
      <pause />
      <preview />
      <appointmentCopy v-if="weekdayLength > 1" />
    </div>
    <saveBtn
      v-if="checkData()"
      class="saveBtn"
      :callback="setCopy"
    />
    <div v-if="weekdayLength > 1">
      <h4>weiteren Tag bearbeiten</h4>
      <getWeekday :key="$store.state.business.weekday + 1" />
    </div>
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  components: {
    getWeekday() {
      return import("@/components/business/applicants/weekday");
    },
    maxApplicants() {
      return import("@/components/business/applicants/maxApplicants");
    },
    appointmentDuration() {
      return import("@/components/business/applicants/appointmentDuration");
    },
    appointmentNext() {
      return import("@/components/business/applicants/appointmentNext");
    },
    appointmentCopy() {
      return import("@/components/business/applicants/appointmentCopy");
    },
    pause() {
      return import("@/components/business/time/pause");
    },
    preview() {
      return import("@/components/business/applicants/preview");
    }
  },
  extends: def,
  data() {
    return {
      weekdayLength: Object.keys(
        this.$store.state.business.unsaved.include.weekdays
      ).length
    };
  },
  mounted() {
    this.$store.commit("business/setCopy", {});
  },
  methods: {
    setCopy() {
      var copy = this.$store.state.business.weekday;
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));

      Object.keys(this.$store.state.business.copy).forEach(
        function(weekday) {
          data.include.weekdays[weekday][2] = data.include.weekdays[copy][2];
          data.max_applicants[weekday] = data.max_applicants[copy];
          data.next_appointments[weekday] = data.next_appointments[copy];
        }.bind(this)
      );

      this.$store.commit("business/prefetch", data);
      this.setSave();
    },
    nextStep() {
      this.$router.push({ name: "settings" });
    },
    checkData() {
      return true;
    }
  }
};
</script>

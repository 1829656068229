<template>
  <div class="webinar">
      <toggleSwitch :value="webinar" title="Kundentermine als Videokonferenz durchführen" :callback="setWebinar" />
      <div v-if="webinar">
        <h4>Webinarsystem</h4>
        <icon id="square-empty-check" /> {{ system.bookingflix }}
      </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      webinar: this.getWebinar(),
      system: {bookingflix: 'Bookingflix (Beta-Version)'}
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setWebinar () {
      this.webinar = !this.webinar
      this.setSave()
    },
    getWebinar () {
      if (typeof this.$store.state.business.unsaved.webinar === 'undefined' || !this.$store.state.business.unsaved.webinar) {
        return false
      } else  {
        return true
      }
    },
    setSave () {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      if (typeof data.webinar === 'undefined' || !data.webinar) {
        data.webinar = Object.keys(this.system)[0];
      } else {
        data.webinar = false
      }
      this.$store.commit("business/prefetch", data);
    }
  }
}
</script>
<style lang="sass" scoped>

</style>

<template>
  <div>
    <h4>Einstellungen übernehmen für ...</h4>
    <ul :key="updateKey">
      <li
        v-for="(day, index) in $store.state.business.unsaved.include.weekdays"
        :key="index"
        v-show="index !== $store.state.business.weekday"
      >
        <a href="#" class="btn btn-info" @click.prevent="setCopy(index)">
          <icon id="square-empty" v-if="!copy[index]" />
          <icon id="square-check" v-else />
          <span>
            {{ $t("dates.weekdays")[index] }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      copy: {},
      updateKey: 0
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setCopy(index) {
      this.copy[index] = !this.copy[index];
      this.updateKey = new Date().getTime();
      this.$store.commit("business/setCopy", this.copy);
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'

ul
  list-style: none
  padding: 0
  display: flex
  align-items: center
  justify-content: flex-start
  li
    margin-right: 5px
</style>

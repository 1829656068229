<template>
  <div>
    <h1>Wofür wird ein Online-Termin-Kalender benötigt?</h1>
    <info>
      Bitte gewünschten Termin-Kalender auswählen
    </info>
    <calendarFormat />
  </div>
</template>
<script>
export default {
  components: {
    calendarFormat() {
      return import("@/components/business/format");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>

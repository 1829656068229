<template>
  <div>
    <!-- <assistentTitle /> -->
    <weekdays />
    <info v-if="!checkData()">Bitte mindestens einen Termin auswählen!</info>
    <div v-if="checkData()">
      <div class="startEndDates">
        <startDate class="item" />
        <endDate class="item" />
      </div>
      <timeRange />
      <lastMinute />
      <!-- <pause /> -->
      <saveBtn
        v-if="checkData()"
        class="saveBtn"
        :callback="setSave"
      />
    </div>
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  components: {
    weekdays() {
      return import("@/components/business/time/weekdays");
    },
    startDate() {
      return import("@/components/business/time/startDate");
    },
    endDate() {
      return import("@/components/business/time/endDate");
    },
    timeRange() {
      return import("@/components/business/settings/range");
    },
    lastMinute() {
      return import("@/components/business/settings/lastMinute");
    },
    /* pause() {
      return import("@/components/business/time/pause");
    } */
  },
  extends: def,
  methods: {
    nextStep() {
      this.$router.push({ name: "applicants" });
    },
    checkData() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      // if (!data.title) {
      // return false
      // }
      if (!Object.keys(data.include.weekdays).length) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="sass" scoped>
.startEndDates
  display: flex
  align-items: flex-start
  justify-content: flex-start
  flex-direction: row
  .item
    text-align: left
    flex: 1
.saveBtn
  margin-top: 30px
  display: inline-block

@media(max-width: 500px)
  .startEndDates
    flex-direction: column
</style>

<template>
  <div>
    <terms :data="data" />
    <columnWidth />
  </div>
</template>
<script>
export default {
  components: {
    terms () { return import('../parts/terms') },
    columnWidth () {
      return import('./width.vue')
    }
  },
  props: {
    data: Object
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>

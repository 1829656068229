<template>
  <ul class="nav">
    <li v-for="(link, index) in links" :key="index" :class="{current: $route.name === Object.keys(link)[0], active: checkStatus(index, getData())}">
      <router-link v-if="checkStatus(index, getData())" :to="{name: Object.keys(link)[0]}"><icon class="icon" id="wrench" v-if="$route.name === Object.keys(link)[0]"/><icon class="icon" id="check" v-else /> {{ Object.values(link)[0] }}</router-link>
      <span class="next" v-else>{{ index + 1 }}. {{ Object.values(link)[0] }}</span>
    </li>
  </ul>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      links: [
        { format: "Format" },
        { styling: "Design" },
        { time: "Zeitraum" },
        { applicants: "Anfragen" },
        { settings: "Einstellungen" },
        { form: "Formular" },
        { survey: "Bewertung" },
        { preview: "Vorschau" }
      ]
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getData () {
      return this.$store.state.business.assistent
    },
    checkStatus (index, data) {

      if (!data.type && index > 0) {
        return false
      }

      if (!data.title && index > 1) {
        return false
      }

      if (!data.form.length && index > 5) {
        return false
      }

      if (!data.survey && index > 6) {
        return false
      }

      if(data.type === 'eventpicker') {
        return this.checkStatusEventpicker(index, data)
      }
      if(data.type === 'datepicker') {
        return this.checkStatusDatepicker(index, data)
      }

      return true
    },
    checkStatusEventpicker (index, data) {
      if(!data.events.length && index > 2) {
        return false
      }
      return true
    },
    checkStatusDatepicker (index, data) {
      if(!Object.keys(data.include.weekdays).length && index > 2) {
        return false
      }
      return true
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
@keyframes blink
  from
    border-color: $dark-grey
  50%
    border-color: $success
  to
    border-color: $dark-grey
ul.nav
  display: flex
  width: 100%
  list-style: none
  padding: 0
  margin: 0
  align-items: center
  justify-content: flex-start
  flex-direction: row
  text-align: center
  box-sizing: border-box
  li
    background-color: transparent
    flex: 1
    border-top: 4px solid $dark-grey
    &.active
      border-color: $success
      &.current
        animation: 1s blink ease-in-out infinite
    a, span.next
      font-size: 8pt
      padding: 10px
      display: block
      text-decoration: none
      color: #666
    a
      .icon
        display: inline-block
      &:hover
        text-decoration: underline
      &.router-link-exact-active
        font-weight: bold
        background-color: rgba(0,0,0,.1)

@media(max-width: 500px)
  ul.nav
    flex-direction: row
    flex-wrap: wrap
    li
      flex: auto
      border-top: 0
      border-bottom: 4px solid $dark-grey
      width: 33%
</style>

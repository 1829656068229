<template>
  <div>
    <h4>
      Wann soll der nachfolgende Termin am
      {{ $t("dates.weekdays")[$store.state.business.weekday] }} stattfinden?
    </h4>
    <div class="range">
      <a href="#" @click.prevent="changeDuration(-1)" class="btn btn-icon">
        <icon id="chevron-left" />
      </a>
      <input
        class="btn"
        :value="values[appointmentDuration]"
        type="text"
        disabled
        step="1"
        max="20"
      />
      <a href="#" @click.prevent="changeDuration(1)" class="btn btn-icon">
        <icon id="chevron-right" />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      appointmentDuration: false,
      values: this.getValues()
    };
  },
  computed: {},
  mounted() {
    this.appointmentDuration = this.getDefaultDuration();
    this.setSave();
  },
  methods: {
    getValues() {
      return {
        "15": "zur nächsten viertel Stunde",
        "30": "zur nächsten halben Stunde",
        "60": "zur nächsten Stunde"
      };
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.next_appointments[
        this.$store.state.business.weekday
      ] = this.appointmentDuration;
      this.$store.commit("business/prefetch", data);
    },
    getDefaultDuration() {
      if (
        typeof this.$store.state.business.unsaved.next_appointments[
          this.$store.state.business.weekday
        ] !== "undefined"
      ) {
        return this.$store.state.business.unsaved.next_appointments[
          this.$store.state.business.weekday
        ];
      }
      return this.$store.state.business.unsaved.include.weekdays[
        this.$store.state.business.weekday
      ][2];
    },
    changeDuration(add) {
      var keys = Object.keys(this.values);
      var index = keys.indexOf(this.appointmentDuration + "");
      index = index + add;

      if (index < 0) {
        index = keys.length - 1;
      }
      if (index === keys.length) {
        index = 0;
      }

      this.appointmentDuration = keys[index];
      this.setSave();
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'
@import '@/assets/sass/range'
.range
  input
    font-size: 20pt
</style>

<script>
import def from './default.vue'
export default {
  extends: def,
  data() {
    return {
      links: [
        { privateStyling: "Allgemein" },
        { privateTime: "Zeitraum" },
        { privateSettings: "Einstellungen" },
        { privatePreview: "Vorschau" }
      ]
    };
  },
  methods: {
    getData () {
      return this.$store.state.privateEvent.assistent
    },
    checkStatus (index, data) {
      if (!data.title && index > 0) {
        return false
      }

      if (!data.events.length && index > 1) {
        return false
      }

      return true
    }
  }
};
</script>

<template>
  <div>
    <component :key="data" :is="data" :callback="setComponent" />
  </div>
</template>
<script>
export default {
  components: {
    register () { return import('./register') },
    signIn () { return import('./signIn') }
  },
  props: {},
  data () {
    return {
      data: 'register'
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setComponent (ret) {
      this.data = ret
    }
  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  .container
    a
      display: block
      font-size: 10pt
      text-align: center
      margin-bottom: 10px
      color: $dark-grey
    h3
      text-align: center

</style>

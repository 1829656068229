<template>
  <div>
    <inputOptional label="Veranstaltungs-Location hinzufügen (Adresse)" :value="location" :callback="setLocation" />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      location: JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved)).location
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setLocation (ret) {
     this.location = ret
     this.setSave()
    },
    setSave () {
      var data = JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved))
      data.location = this.location
      this.$store.commit("privateEvent/prefetch", data);
    }
  }
}
</script>
<style lang="sass" scoped>
</style>

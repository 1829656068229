<template>
    <a href="#" class="btn btn-info" @click.prevent="setRequired()">
      <icon id="square-check" v-if="checkRequired()" />
      <icon id="square-empty" v-else />
      Pflichtfeld
    </a>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      data: JSON.parse(JSON.stringify(this.$store.state.business.unsaved.form[this.$store.state.form.edit])),
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setRequired () {
      if (this.data.required === true || this.data.required === 'true') {
        this.data.required = false
      } else if (this.data.required === false || this.data.required === 'false') {
        this.data.required = true
        }
      this.setSave()
    },
    checkRequired () {
      if (this.data.required === true || this.data.required === 'true') {
        return true
      } else {
        return false
      }
    },
    setSave () {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.form[this.$store.state.form.edit] = this.data;
      this.$store.commit("business/prefetch", data);
    }
  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  a.btn
    margin-top: 10px
    font-size: 12pt

</style>

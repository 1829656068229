<template>
  <div class="container">
    <div class="item register">
      <form>
        <account />
      </form>
    </div>
    <div class="item preview" v-if="$store.state.business.unsaved.type !== 'services'">
      <h3>Vorschau des Terminkalenders</h3>
      <hit-calendar id="1" :data="data" callback=""/>
    </div>
    <div class="item preview" v-else>
      <info>
        Angebote / Dienstleistungen können im Loginbereich erstellt und anschließend mit dem Terminkalender verknüpft werden.
      </info>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    account () { return import('./account') }
  },
  props: {},
  data () {
    return {
      data: btoa(JSON.stringify({
        data: this.$store.state.business.unsaved
      }))
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  .container
    display: flex
    margin-left: -10px
    margin-right: -10px
    h3
      margin-top: 0
      text-align: center
    .item
      margin: 10px
    .item.register
      width: 30%
      border: 2px dotted $dark-grey
      border-radius: 10px
      padding: 10px
      box-sizing: border-box
    .item.preview
      flex: 1

  @media(max-width: 500px)
    .container
      flex-direction: column-reverse
      margin: 0
      .item.register, .item.preview
        width: 100%
        flex: 1
</style>

<template>
  <div>
    <preview />
  </div>
</template>
<script>
export default {
  components: {
    preview () { return import('@/components/private/preview/index.vue') }
  },
  props: {},
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>

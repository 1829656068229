<template>
  <div>
    <h4>Zeitraum, um Termine im Voraus zu buchen</h4>
    <div class="range">
      <a href="#" @click.prevent="changeDuration(-1)" class="btn btn-icon">
        <icon id="chevron-left" />
      </a>
      <input
        class="btn"
        :value="values[period]"
        type="text"
        disabled
        step="1"
        max="20"
      />
      <a href="#" @click.prevent="changeDuration(1)" class="btn btn-icon">
        <icon id="chevron-right" />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      default: "2 months",
      period: false,
      values: {
        "2 days": "2 Tage",
        "7 days": "7 Tage",
        "2 weeks": "14 Tage",
        "1 months": "1 Monat",
        "2 months": "2 Monate",
        "3 months": "3 Monate",
        "6 months": "6 Monate",
        "12 months": "12 Monate"
      }
    };
  },
  computed: {},
  mounted() {
    this.period = this.getDefaultDuration();
    this.setSave();
  },
  methods: {
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved))
      data.period = this.period.split(" ")
      data.period[0] = data.period[0] * 1
      this.$store.commit("business/prefetch", data)
    },
    getDefaultDuration() {
      if (
        typeof this.$store.state.business.unsaved.period !== "object" ||
        !this.$store.state.business.unsaved.period.length
      ) {
        return this.default;
      }
      return this.$store.state.business.unsaved.period.join(" ");
    },
    changeDuration(add) {
      var keys = Object.keys(this.values);
      var index = keys.indexOf(this.period + "");
      index = index + add;

      if (index < 0) {
        index = keys.length - 1;
      }
      if (index === keys.length) {
        index = 0;
      }

      this.period = keys[index];
      this.setSave();
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'
@import '@/assets/sass/range'
.range
  input
    font-size: 20pt
</style>

<template>
  <div>
    <div v-if="$store.state.business.unsaved.bookingMethod === 'default'">
      <h4>Zeit für die Verifizierung des Termins</h4>
      <info>
        Der Termin bleibt geblockt (nicht buchbar), bis der buchende Kunde diesen verifiziert hat oder die hier angegebene Zeit abgelaufen ist. Die Verifizierung erfolgt per Link-Klick in der Verifizierungs-Email.
      </info>
      <div class="range">
        <a href="#" @click.prevent="changeVerifyTime(-1)" class="btn btn-icon">
          <icon id="chevron-left" />
        </a>
        <input
          class="btn"
          :value="values[verifyTime]"
          type="text"
          disabled
          step="1"
          max="20"
        />
        <a href="#" @click.prevent="changeVerifyTime(1)" class="btn btn-icon">
          <icon id="chevron-right" />
        </a>
      </div>
    </div>
    <div class="danger" v-else>
      <icon id="circle-alert" /> Dieser Buchungsmodus entspricht nicht der europäischen Datenschutzgrundverordnung. Wir empfehlen die Nutzung ausschließlich zu Testzwecken.
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      default: "2",
      verifyTime: this.getDefaultVerifyTime(),
      values: {
        "1": "1 Stunde",
        "2": "2 Stunden",
        "3": "3 Stunden",
        "6": "6 Stunden",
        "12": "12 Stunden",
        "18": "18 Stunden",
        "24": "24 Stunden",
        "48": "48 Stunden"
      }
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    getDefaultVerifyTime() {
      if (!this.$store.state.business.unsaved.verifyTime) {
        return this.default;
      }
      return this.$store.state.business.unsaved.verifyTime;
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.verifyTime = this.verifyTime;
      this.$store.commit("business/prefetch", data);
    },
    changeVerifyTime(add) {
      var keys = Object.keys(this.values);
      var index = keys.indexOf(this.verifyTime + "");
      index = index + add;

      if (index < 0) {
        index = keys.length - 1;
      }
      if (index === keys.length) {
        index = 0;
      }

      this.verifyTime = keys[index];
      this.setSave();
    }
  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables'
  @import '@/assets/sass/range'
  .danger
    color: $danger
  .range
    input
      font-size: 16pt
</style>

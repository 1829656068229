<template>
  <div>
    <calendarTitle />
    <calendarColor />
    <calendarButtonText />
    <saveBtn
      v-if="checkData()"
      class="saveBtn"
      :callback="function() { setSave(); }"
    />
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  components: {
    calendarTitle() {
      return import("@/components/business/title");
    },
    calendarColor() {
      return import("@/components/business/styling/color");
    },
    calendarButtonText() {
      return import("@/components/business/styling/buttonText");
    }
  },
  extends: def,
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    nextStep() {
      this.$router.push({ name: "time" });
    },
    checkData() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      if (!data.title) {
        return false;
      }
      return true;
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'
.saveBtn
  margin-top: 20px
  display: inline-block
</style>

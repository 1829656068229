<template>
  <div class="width">
    <ul>
      <li v-for="width in widths" :key="width">
        <a href="#" class="btn" :class="{'btn-invert': width === data.columnName * 1}" @click.prevent="setWidth(width)"><icon id="resize-horizontal" style="font-weight: bold"/> | {{ Math.round(width / 12 * 100) }}%</a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      data: JSON.parse(JSON.stringify(this.$store.state.business.unsaved.form[this.$store.state.form.edit])),
      widths: [12, 8, 6, 4, 3]
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setWidth (width) {
      this.data.columnName = width
      this.setSave()
    },
    setSave () {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.form[this.$store.state.form.edit] = this.data;
      this.$store.commit("business/prefetch", data);
    },
  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  $margin: 5px
  .width
    float: right
    margin-top: 20px
    ul
      list-style: none
      padding: 0
      margin: 0
      display: flex
      margin-left: -$margin
      margin-right: -$margin
      flex-wrap: wrap
      li
        margin: $margin
        a
          font-size: 10pt
          text-decoration: none
          color: $dark-grey
          font-size: 10pt
          padding: 5px
          height: 25px
</style>

<template>
  <div class="formEditor">
    <addElement index="top"/>
    <div class="stage">
      <div class="item" v-for="(element, index) in $store.state.business.unsaved.form" :key="element.id" :style="{width: (element.columnName / 12 * 100 -5)+'%'}">
        <editMenu :id="index" />
        <component :is="getEdit(element, index)" :data="element" style="display: block; width: 100%" />
      </div>
    </div>
    <addElement index="bottom"/>
  </div>
</template>
<script>
export default {
  components: {
    editMenu () { return import('./editMenu') },
    addElement () { return import('./addElement') },
    headerView () { return import('./parts/header') },
    paragraphView () { return import('./parts/paragraph') },
    textView () { return import('./parts/text') },
    textareaView () { return import('./parts/textarea') },
    termsView () { return import('./parts/terms') },
    agbView () { return import('./parts/agb') },
    checkboxView () { return import('./parts/checkbox') },
    radioView () { return import('./parts/radio') },

    headerEdit () { return import('./editor/header') },
    termsEdit () { return import('./editor/terms') },
    paragraphEdit () { return import('./editor/paragraph') },
    textEdit () { return import('./editor/text') },
    textareaEdit () { return import('./editor/textarea') },
    agbEdit () { return import('./editor/agb') },
    checkboxEdit () { return import('./editor/checkbox') },
    radioEdit () { return import('./editor/radio') },
  },
  props: {},
  data () {
    return {
      form: this.$store.state.business.unsaved.form,
      updateKey: 0
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    getEdit (element, index) {

      if (this.$store.state.form.edit === index) {
        return element.type+'Edit'
      }

      return element.type+'View'
    }
  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  .formEditor
    margin-left: -5px
    margin-right: -5px
    .stage
      border: 2px dotted $dark-grey
      margin-bottom: 10px
      padding: 10px
      box-sizing: border-box
      overflow: hidden
      .item
        display: flex
        display: inline-flex
        justify-content: flex-start
        align-items: flex-start
        margin: 5px
</style>

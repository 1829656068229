<template>
  <div>
    <h4>Kurzfristige Terminbuchungen ausschließen</h4>
    <div class="range">
      <a href="#" @click.prevent="changeDuration(-1)" class="btn btn-icon">
        <icon id="chevron-left" />
      </a>
      <input
        class="btn"
        :value="values[lastminute]"
        type="text"
        disabled
        step="1"
        max="20"
      />
      <a href="#" @click.prevent="changeDuration(1)" class="btn btn-icon">
        <icon id="chevron-right" />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      default: "0",
      lastminute: false,
      values: {
        "0": "Last-Minute Buchungen sind erlaubt",
        "15": "bis 15 Minuten vor Termin",
        "30": "bis 30 Minuten vor Termin",
        "45": "bis 45 Minuten vor Termin",
        "60": "bis 1 Stunde vor Termin",
        "120": "bis 2 Stunden vor Termin",
        "180": "bis 3 Stunden vor Termin",
        "240": "bis 4 Stunden vor Termin",
        "300": "bis 5 Stunden vor Termin",
        "360": "bis 6 Stunden vor Termin",
        "720": "bis 12 Stunden vor Termin",
        "1440": "bis 1 Tag vor Termin",
        "2880": "bis 2 Tage vor Termin"
      }
    };
  },
  computed: {},
  mounted() {
    this.lastminute = this.getDefaultDuration();
    this.setSave();
  },
  methods: {
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.lastminute = this.lastminute;
      this.$store.commit("business/prefetch", data);
    },
    getDefaultDuration() {
      if (!this.$store.state.business.unsaved.lastminute) {
        return this.default;
      }
      return this.$store.state.business.unsaved.lastminute;
    },
    changeDuration(add) {
      var keys = Object.keys(this.values);
      var index = keys.indexOf(this.lastminute + "");
      index = index + add;

      if (index < 0) {
        index = keys.length - 1;
      }
      if (index === keys.length) {
        index = 0;
      }

      this.lastminute = keys[index];
      this.setSave();
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'
@import '@/assets/sass/range'
.range
  input
    font-size: 16pt
</style>

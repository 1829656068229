<template>
  <div id="app">
    <div class="container">
      <div class="stage">
        <div class="switcher">
          <switcher />
        </div>
        <transition name="pageSwitch">
          <router-view />
        </transition>
      </div>
      <div class="menu">
        <assistentNavigation />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    assistentNavigation() {
      return import("@/components/navigation/index.vue");
    },
    switcher() {
      return import("@/components/switcher/index.vue");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {
    if (this.$route.name === null) {
      this.$router.push({ name: "format" });
    }
  },
  methods: {}
};
</script>
<style lang="sass" scoped>
$radius: 7px
$shadow: 0px 5px 10px rgba(0,0,0,0.4)
$padding: 30px
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap')
@keyframes openPage
  from
    transform: scaleX(0)
    opacity: 0
  to
    transform: scaleX(1)
    opacity: 1

.pageSwitch-enter-active
  transform-origin: center left
  animation: 0.3s openPage ease-in-out
#app
  max-width: 960px
  margin: 0 auto
  font-family: Poppins,sans-serif
  font-weight: 400
  .container
    .stage
      box-shadow: $shadow
      min-height: 50vh
      border-top-left-radius: $radius
      border-top-right-radius: $radius
      padding: $padding
      box-sizing: border-box
    .menu
      background: #ccc
      padding: 0 $padding
      overflow: hidden
      box-shadow: $shadow
      display: block
      border-bottom-left-radius: $radius
      border-bottom-right-radius: $radius
    @media (max-width: 500px)
      .menu
        padding: 0
</style>

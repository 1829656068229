<script>
import def from './default.vue'
export default {
  extends: def,
  data() {
    return {
      links: [
        { format: "Format" },
        { preview: "Vorschau" }
      ]
    };
  },
  methods: {
    checkStatus (index, data) {

      if (!data.type && index > 0) {
        return false
      }

      return true
    }
  }
};
</script>

<template>
  <div>
    <ul v-if="open">
      <li>
        <a href="#" class="btn btn-info" @click.prevent="addHeader"><icon id="plus" /> Überschrift <span>({{ getCount().header || 0 }})</span></a>
      </li>
      <li>
        <a href="#" class="btn btn-info" @click.prevent="addParagraph"><icon id="plus" /> Erklärtext <span>({{ getCount().paragraph || 0 }})</span></a>
      </li>
      <li v-if="checkDoublicate('tel')">
        <a href="#" class="btn btn-info" @click.prevent="addTel"><icon id="plus" /> Telefonnummer <span>({{ getCount().tel || 0 }})</span></a>
      </li>
      <li>
        <a href="#" class="btn btn-info" @click.prevent="addText"><icon id="plus" /> Textfeld <span>({{ getCount().text || 0 }})</span></a>
      </li>
      <li>
        <a href="#" class="btn btn-info" @click.prevent="addTextarea"><icon id="plus" /> Textblock <span>({{ getCount().textarea || 0 }})</span></a>
      </li>
      <li>
        <a href="#" class="btn btn-info" @click.prevent="addRadio"><icon id="plus" /> Einzelauswahl <span>({{ getCount().radio || 0 }})</span></a>
      </li>
      <li>
        <a href="#" class="btn btn-info" @click.prevent="addCheckbox"><icon id="plus" /> Mehrfachauswahl <span>({{ getCount().checkbox || 0 }})</span></a>
      </li>
      <li v-if="checkDoublicate('agb')">
        <a href="#" class="btn btn-info" @click.prevent="addAGB"><icon id="plus" /> AGB <span>({{ getCount().agb || 0 }})</span></a>
      </li>
    </ul>

    <a href="#" v-else @click.prevent="open = !open" class="btn btn-info btn-invert btn-block"><icon id="plus" /> Neues Formular-Element beifügen</a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    index: [Number, String]
  },
  data () {
    return {
      open: true
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    getCount () {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved)).form

      var c = {}
      data.forEach(function (d) {
        var type = d.type
        if (type === 'text') {
          type = d.subtype
        }
        if (typeof c[type] === 'undefined') {
          c[type] = 0
        }
        c[type] = c[type] + 1
      })
     return c
    },
    addCheckbox () {
      var copy = {"type":"checkbox","subtype":"checkbox","label":"Mehrfachauswahl","columnName":"12","description":"","required":false,"value":"","values":[{"label":"Option 1","value":"Option 1"},{"label":"Option 2","value":"Option 2"},{"label":"Option 3","value":"Option 3"}]};
      this.setSave(copy)
    },
    addRadio () {
      var copy = {"type":"radio","subtype":"radio","label":"Einzelauswahl","columnName":"12","description":"","required":false,"value":"","values":[{"label":"Option 1","value":"Option 1"},{"label":"Option 2","value":"Option 2"},{"label":"Option 3","value":"Option 3"}]}
      this.setSave(copy)
    },
    addAGB () {
      var copy = {"type":"agb","subtype":"agb","label":"Ich stimme den AGBs zu","columnName":"12","description":"<p>Ja das sind die AGBs</p>","required":true,"value":"","values":["yes","no"]}
      this.setSave(copy)
    },
    addTel () {
      var copy = { type: 'text', subtype: 'tel', label: 'Handynummer', columnName: '12', description: '', required: false }
      this.setSave(copy)
    },
    addText () {
      var copy = { type: 'text', subtype: 'text', label: '', columnName: '12', description: '', required: false }
      this.setSave(copy)
    },
    addTextarea () {
      var copy = { type: 'textarea', subtype: 'textarea', label: '', columnName: '12', description: '', required: false }
      this.setSave(copy)
    },
    addParagraph () {
      var copy = { "type": "paragraph", "subtype": "p", "label": "", "columnName": "12", "description": "", "required": false }
      this.setSave(copy)
    },
    addHeader () {
      var copy = { "type": "header", "subtype": "h2", "label": "", "columnName": "12", "description": "", "required": false }
      this.setSave(copy)
    },
    setSave (copy){
      copy.id = new Date().getTime()
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved))
      var pos = this.index

      if (pos === 'top') {
        pos = 0
        data.form.splice(pos, 0, copy)
      }
      if (pos === 'bottom') {
        data.form.push(copy)
        pos = data.form.length -1
      }

      this.$store.commit("business/prefetch", data)
      this.$store.commit('form/setEdit', pos)
    },
    checkDoublicate (part) {
      var form = this.$store.state.business.unsaved.form
      var r = true
      form.forEach(function (f) {
        if (f.subtype === part || f.type === part) {
          r = false
        }
      })
      return r
    }
  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  a
    margin: 20px 0
  ul
    list-style: none
    padding: 10px
    margin: 0
    margin-left: -5px
    margin-right: -5px
    box-sizing: border-box
    width: 100%
    display: flex
    justify-content: flex-start
    align-items: space-between
    flex-wrap: wrap
    margin-bottom: 10px
    background-color: $light-grey
    li
      margin: 5px
      flex: 1
      a
        background-color: white
        margin: 0
        width: 100%
        font-size: 10pt
        span:last-child
          font-size: 7pt
          margin-left: 2px
          display: inline-block
</style>

<template>
  <div>
    <eventsList />
    <eventsAdd />
    <saveBtn
      v-if="$store.state.business.unsaved.events.length"
      class="saveBtn"
      :callback="setSave"
    />
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  extends: def,
  components: {
    eventsList () { return import('./eventsList') },
    eventsAdd () { return import('./eventsAdd') }
  },
  methods: {
    nextStep() {
      this.$router.push({ name: "applicants" });
    },
  }
}
</script>
<style lang="sass" scoped>
  .saveBtn
    margin-top: 20px
    display: inline-block
</style>

<template>
  <div>
    <h1>Anfragen</h1>
    <calendarApplicants />
  </div>
</template>
<script>
export default {
  components: {
    calendarApplicants() {
      return import("@/components/business/applicants/index.vue");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>

<template>
  <div>
    <h4>An welchen Tagen hat Ihr Geschäft geöffnet?</h4>
    <transition-group class="container" name="changeWeekday">
      <div
        href="#"
        class="item"
        v-for="(checkActive, key) in dates"
        :key="weekdays[key]"
        :class="{ active: checkActive, inactive: !checkActive }"
      >
        <b>{{ weekdays[key] }}</b>

        <span class="clock" v-if="checkActive">
          <timePickerRange
            :time="active[key]"
            :callback="function(ret) { changeTime(key, ret); }"
          />
        </span>
        <span class="clock" v-else>Geschlossen</span>
        <a href="#" @click.prevent="setActive(key)">
          <icon class="icon" v-if="checkActive" id="check" />
          <icon class="icon" v-else id="menu-close" />
        </a>
      </div>
    </transition-group>
  </div>
</template>
<script>
export default {
  components: {
    timePickerRange() {
      return import("@/components/default/form/timePickerRange");
    }
  },
  props: {},
  data() {
    return {
      weekdays: this.$t("dates.weekdays"),
      active: JSON.parse(
        JSON.stringify(this.$store.state.business.unsaved.include.weekdays)
      ),
      updateKey: new Date().getTime(),
      dates: false
    };
  },
  mounted() {
    this.dates = this.getWeekdays();
  },
  methods: {
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.include.weekdays = this.active;

      Object.keys(data.include.weekdays).forEach(function (k) {

        if (typeof data.max_applicants[k] === 'undefined'){
          data.max_applicants[k] = 1
        }
        if (typeof data.next_appointments[k] === 'undefined'){
          data.next_appointments[k] = 60
        }
      })

      this.$store.commit("business/prefetch", data);
    },
    changeTime(ret, time) {
      this.active[ret] = time;
      this.setSave();
    },
    getWeekdays() {
      var r = {};
      var active = this.active;
      this.weekdays.forEach(function(weekday, key) {
        r[key] = false;
        if (typeof active[key] !== "undefined") {
          r[key] = true;
        }
      });
      return r;
    },
    setActive(index) {
      if (typeof this.active[index] === "object") {
        delete this.active[index];
        this.dates[index] = false;
      } else {
        this.dates[index] = true;
        this.active[index] = ["09:00", "18:00", 60];
      }
      this.setSave();
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/weekdays.sass'
</style>

<template>
  <span class="error" v-if="!checkDate($store.state.business.unsaved.events[this.index])">
    <icon id="square-alert" /> Bitte Termindatum überprüfen
  </span>
</template>
<script>
export default {
  components: {},
  props: {
    index: Number
  },
  data () {
    return {
      error: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    checkDate (event) {

      var d1 = new Date()
      d1.setYear(event.begin.split(" ")[0].split("-")[0])
      d1.setMonth(event.begin.split(" ")[0].split("-")[1])
      d1.setDate(event.begin.split(" ")[0].split("-")[2])
      d1.setHours(event.begin.split(" ")[1].split(":")[0])
      d1.setMinutes(event.begin.split(" ")[1].split(":")[1])
      d1.setSeconds(0)
      d1.setMilliseconds(0)

      if (d1.getTime() < new Date().getTime()) {
        return false
      }

      if (!event.end) {
        return true
      }

      var d2 = new Date()
      d2.setYear(event.end.split(" ")[0].split("-")[0])
      d2.setMonth(event.end.split(" ")[0].split("-")[1])
      d2.setDate(event.end.split(" ")[0].split("-")[2])
      d2.setHours(event.end.split(" ")[1].split(":")[0])
      d2.setMinutes(event.end.split(" ")[1].split(":")[1])
      d2.setSeconds(0)
      d2.setMilliseconds(0)

      if (d2.getTime() < d1.getTime()) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  .error
    color: $danger
    width: 100%
    display: block
    text-align: center
</style>

<template>
  <div>
    <h1>Private Termine</h1>
    <styling />
  </div>
</template>
<script>
export default {
  components: {
    styling () { return import('@/components/private/styling/index.vue') }
  },
  props: {},
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>

import { render, staticRenderFns } from "./appointmentDuration.vue?vue&type=template&id=584c2c12&scoped=true&"
import script from "./appointmentDuration.vue?vue&type=script&lang=js&"
export * from "./appointmentDuration.vue?vue&type=script&lang=js&"
import style0 from "./appointmentDuration.vue?vue&type=style&index=0&id=584c2c12&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "584c2c12",
  null
  
)

export default component.exports
<template>
  <component :is="data.subtype" v-html="data.label" class="header" />
</template>
<script>
export default {
  components: {},
  props: {
    data: Object
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  .header
    margin-top: 0px
</style>

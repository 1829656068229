<template>
  <div>
    <h4>Farbe des Terminkalenders</h4>
    <colorPicker
      :value="color"
      :callback="setColor"
    />
  </div>
</template>
<script>
export default {
  components: {
    colorPicker() {
      return import("@/components/default/colorpicker/");
    }
  },
  props: {},
  data() {
    return {
      color: this.$store.state.business.unsaved.styling.accent
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setColor(c) {
      this.color = c;
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.styling.accent = this.color;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>

<script>
export default {
  components: {},
  beforeDestroy() {
    var data = JSON.parse(JSON.stringify(this.$store.state.business.assistent));
    this.$store.commit("business/prefetch", data);
  },
  methods: {
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      this.$store.commit("business/update", data);

      this.nextStep();
    },
    finalStep() {},
    nextStep() {
      return false;
    },
    checkData() {
      return true;
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'
.saveBtn
  margin-top: 20px
  display: inline-block
</style>

<script>
import def from '@/components/business/applicants/maxEventApplicants'
export default {
  extends: def,
  methods: {
    getEvents () {
      return this.$store.state.privateEvent.unsaved
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.getEvents()));
      data.max_applicants = this.maxApplicants;
      this.$store.commit("privateEvent/prefetch", data);
    }
  }
}
</script>

<template>
  <div>
    <a href="#" class="btn btn-info" @click.prevent="addEvent"><icon id="plus" /> Termin hinzufügen <span class="count">({{ getEvents().events.length }})</span></a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    getEvents () {
      return this.$store.state.business.unsaved
    },
    addEvent () {
      var data = JSON.parse(JSON.stringify(this.getEvents()));

      var time = new Date()
      time.setMinutes(0)
      time.setDate( time.getDate() + 1 )
      var date = time.getFullYear() + '-' + this.getNullsBefore((time.getMonth() + 1)) + '-' + this.getNullsBefore(time.getDate()) + ' ' +this.getNullsBefore(time.getHours()) + ':' + this.getNullsBefore(time.getMinutes())

      data.events.push({"begin": date,"end": date,"style":"","value":{"ID":"fixed","name":"","label":""}});
      data.max_applicants[(data.events.length - 1)] = 1
      this.setSave(data)
    },
    setSave (data) {
      this.$store.commit("business/prefetch", data);
    },
    getNullsBefore(index) {
      if (index * 1 < 10) {
        return "0" + index;
      } else {
        return index + "";
      }
    },
  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  a
    span.count
      font-size: 8pt
      display: inline-block
      margin-left: 5px
</style>

<template>
  <div>
    <formEditor />
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  extends: def,
  components: {
    formEditor () { return import('@/components/business/form/preview/') }
  },
  props: {},
  data () {
    return {
      default: [
        { id: 1580201858868, type: 'header', subtype: 'h2', label: 'Termin-Buchungs-Formular', columnName: '12', description: '', required: false },
        { id: 1580201853354, type: 'paragraph', subtype: 'p', description: 'Um eine Terminbuchung vormerken zu lassen,  werden folgende Angaben benötigt. Alle Pflichtfelder sind mit Sternchen markiert. Nach dem Ausfüllen des Formulars wird direkt vom System eine Verifizierungs-Email gesendet. Eine endgültige Terminzusage / Absage kann erst nach erfolgreicher Verifizierung erfolgen.', columnName: '12', required: false, value: '' },
        { id: 1580201857939, type: 'header', subtype: 'h3', label: 'Die benötigten Angaben', columnName: '12', description: '', required: false }, { id: 1580201857398, type: 'text', subtype: 'text', label: 'Vorname', columnName: '6', description: '', required: true }, { id: 1580201859290, type: 'text', subtype: 'text', label: 'Nachname', columnName: '6', description: '', required: true }, { id: 1580201857321, type: 'text', subtype: 'email', label: 'E-Mailadresse', columnName: '6', description: 'für Terminerinnerungen', required: true },
        { id: 1580201853552, type: 'text', subtype: 'tel', label: 'Handynummer', columnName: '6', description: 'für Terminerinnerungen (optional)', required: false },
        { id: 1580201854637, type: 'textarea', label: 'Freitextfeld für Anmerkungen / Infos an den Dienstleister', columnName: '12', description: 'optional', required: false },
        { id: 1580201862754, type: 'header', subtype: 'h3', label: 'Datenschutz', columnName: '12', description: '', required: false },
        { id: 1580201859939, type: 'terms', label: 'Datenschutz-Informationen (Bitte bestätigen)', columnName: '12', required: true, description: 'Sie haben die Möglichkeit jederzeit Ihre Einwilligung zur Verwendung der persönlichen Daten zu widerrufen.', values: [{ label: 'Ich stimme der Erhebung meiner personenbezogenen Daten (siehe Formular) zu', value: 'Einwilligung personenbezogener Daten' }, { label: 'Ich stimme der Kontaktaufnahme zwecks Terminerinnerungen zu', value: 'Kontaktaufnahme Terminerinnerungen' }, { label: 'Ich bestätige die Richtigkeit meiner Angaben', value: 'Richtigkeit Angaben' }] }
      ],
      form: this.$store.state.business.unsaved.form
    }
  },
  computed: {

  },
  mounted () {
    if (this.$store.state.business.unsaved.form.length === 0) {
      this.form = this.default
      this.setSave()
    }
  },
  methods: {
    setSave () {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.form = this.form;
      this.$store.commit("business/prefetch", data);
    }
  }
}
</script>
<style lang="sass" scoped>
</style>

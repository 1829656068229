import Vue from "vue";
import Vuex from "vuex";
import business from "@/store/business.js";
import privateEvent from "@/store/private.js";
import form from "@/store/form.js";
Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    type: "business"
  },
  mutations: {
    setType(store, type) {
      store.type = type;
    }
  },
  actions: {},
  modules: {
    business,
    privateEvent,
    form
  }
});

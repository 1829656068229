<template>
  <div>
    <h1>Termin-Formular</h1>
    <assistentForm />
  </div>
</template>
<script>
export default {
  components: {
    assistentForm () { return import('@/components/business/form/') }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>

<template>
  <div>
    <saveBtn
      v-if="checkData()"
      class="saveBtn"
      :callback="setSave"
    />
    <stage />
    <saveBtn
      v-if="checkData()"
      class="saveBtn"
      :callback="setSave"
    />
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  extends: def,
  components: {
    stage () { return import('./stage.vue') }
  },
  methods: {
    nextStep() {
      this.$router.push({ name: "survey" });
    }
  }
}
</script>
<style lang="sass" scoped>
  .saveBtn
    margin: 10px 0
    display: inline-block
</style>

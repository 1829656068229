import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import plugin from "@/plugin/index.js";
import i18n from "@/lang/index.js";

import "document-register-element/build/document-register-element";
import vueCustomElement from "vue-custom-element";

Vue.config.productionTip = false;

Vue.use(plugin);
Vue.use(vueCustomElement);

App.store = store;
App.router = router;
App.i18n = i18n;
Vue.customElement("flix-assistent", App);

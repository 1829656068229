<template>
  <div class="container">
    <div clas="item">
      <toggleSwitch
        title="E-Mail-Verifizierung"
        :value="data"
        :callback="setValue"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      data: this.getData()
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    getData () {
      if (this.$store.state.business.unsaved.bookingMethod === 'default'){
        return true
      } else {
        return false
      }
    },
    setValue () {
      if (this.data === true) {
        this.data = false
      } else {
        this.data = true
      }
      this.setSave()
    },
    setSave () {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      if (this.data) {
        data.bookingMethod = 'default'
      } else {
        data.bookingMethod = 'noVerify'
      }
      this.$store.commit("business/prefetch", data);
    }
  }
}
</script>
<style lang="sass" scoped>
  .container
    margin-top: 10px
</style>

<template>
  <div>
    <inputOptional :value="password" label="Zugangs-Passwort des Terminkalenders" :callback="setPassword"/>
    <info style="display: inline">
      Passwortschutz ist optional
    </info>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      password: JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved)).password
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setPassword (val) {
      this.password = val
      this.setSave()
    },
    setSave () {
      var data = JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved))
      data.password = this.password
      this.$store.commit("privateEvent/prefetch", data);
    }
  }
}
</script>
<style lang="sass" scoped>
</style>

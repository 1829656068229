<template>
  <div class="calendarData">
    <div>
      <h4>Name der Kalender-Kontaktperson</h4>
      <inputText
        :data="name"
        label=""
        :callback="setName"
      />
    </div>
    <div>
      <h4>Email der Kalender-Kontaktperson</h4>
      <inputEmail
        :data="email"
        label=""
        :callback="setEmail"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      name: this.$store.state.business.unsaved.contactName,
      email: this.$store.state.business.unsaved.contactEmail
    };
  },
  methods: {
    setName(val) {
      this.name = val;
      this.setSave();
    },
    setEmail(val) {
      this.email = val;
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.contactName = this.name;
      data.contactEmail = this.email;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped>
.calendarData
  display: flex
  flex-wrap: wrap
  div
    flex: 1 auto
    margin: 5px
    margin-left: 0
</style>

<template>
  <div>
    <h4>E-Mailadresse des Terminverwalters</h4>
    <inputEmail :data="email" label="" :callback="setEmail"/>
    <info>
       Per E-Mail über eingehende Termine benachrichtigen
    </info>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      email: JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved)).email
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setEmail (val) {
      this.email = val
      this.setSave()
    },
    setSave () {
      var data = JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved))
      data.email = this.email
      this.$store.commit("privateEvent/prefetch", data);
    }
  }
}
</script>
<style lang="sass" scoped>
</style>

<template>
  <div clas="container">
    <h3>Bookingflix-Account</h3>
    <inputText label="E-Mailadresse / Benutzername" value="dsdsds" />
    <inputPassword label="Password" value="dsdsds" />
    <saveBtn
      class="saveBtn"
      label="Einloggen"
      :callback="function() { setSave(); }"
    />
    <a href="#" class="btn" @click.prevent="callback('register')">... oder neu registrieren?</a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  .container
    a
      display: block
      font-size: 10pt
      text-align: center
      margin-bottom: 10px
      color: $dark-grey
    h3
      text-align: center

</style>

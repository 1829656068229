<template>
  <div class="container">
    <div v-if="error" class="error">
      <icon id="triangle-alert" />
    </div>
    <div>
      <timePicker
        :time="data[0]"
        :callback="function(ret) { data[0] = ret; setCallback(); }"
      />
    </div>
    <div><icon id="minus" /></div>
    <div>
      <timePicker
        :time="data[1]"
        :callback="function(ret) { data[1] = ret; setCallback(); }"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    timePicker() {
      return import("@/components/default/form/timePicker");
    }
  },
  props: {
    time: Array,
    callback: Function
  },
  data() {
    return {
      data: JSON.parse(JSON.stringify(this.time)),
      error: false
    };
  },
  computed: {},
  mounted() {
    this.checkDate();
  },
  methods: {
    checkDate() {
      if (
        this.data[0].split(":").join("") * 1 <=
        this.data[1].split(":").join("") * 1
      ) {
        this.error = false;
      } else {
        this.error = true;
      }
    },
    setCallback() {
      this.checkDate();
      if (!this.error) {
        this.callback(this.data);
      }
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/timePickerRange.sass'
</style>

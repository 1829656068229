<template>
  <div>
    <toggleSwitch title="Anonymiserte Darstellung der Terminbuchungen" :value="anonym" :callback="setAnonym" />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      anonym: this.getAnonym()
    }
  },
  methods: {
    setAnonym () {
      this.anonym = !this.anonym
      this.setSave()
    },
    getAnonym () {
      if(!JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved)).exclude.anonym) {
        return false
      }
      return true
    },
    setSave () {
      var data = JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved))
      data.exclude.anonym = this.anonym
      this.$store.commit("privateEvent/prefetch", data);
    }
  }
}
</script>
<style lang="sass" scoped>
</style>

import Vue from "vue";
import VueI18n from "vue-i18n";

import deDefault from "./de/default.json";
import deDates from "./de/dates.json";
// import { enEN } from '@/language/en_EN.js'

Vue.use(VueI18n);

const messages = {
  de: {
    default: deDefault,
    dates: deDates
  }
  //  en: enEN
};

// Create VueI18n instance with options
export default new VueI18n({
  locale: "de", // set locale
  fallbackLocale: "de",
  messages // set locale messages
});

<template>
  <div>
    <label v-if="label" :for="id">{{ label }}</label>
    <div class="input-container" :class="{ active: check }">
      <div>
        <span>
          <icon id="check" v-if="check" />
          <icon id="menu-close" v-else />
        </span>
      </div>
      <input
        autocomplete="current-password"
        v-model="value"
        :id="id"
        :name="id"
        type="password"
        spellcheck="false"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: [String, Number],
    label: String,
    callback: Function
  },
  data() {
    return {
      check: false,
      id: new Date().getTime() + Math.random(),
      value: this.data
    };
  },
  computed: {},
  mounted() {
    this.checkValue();
  },
  watch: {
    value() {
      this.check = false;
      this.checkValue();
      this.returnValue();
    }
  },
  methods: {
    returnValue() {
      if (typeof this.callback !== "function") {
        return false;
      }

      if (this.check === true) {
        this.callback(this.value);
      } else {
        this.callback("");
      }
    },
    checkValue() {
      if (this.value && this.value.length >= 5) {
        this.check = true;
      }
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/input.sass'
</style>

<template>
  <div>
    <h4>Gewünschter Titel des Terminkalenders</h4>
    <inputText
      :data="title"
      label=""
      :callback="function(value) { setTitle(value);}"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: JSON.parse(JSON.stringify(this.$store.state.business.unsaved))
        .title
    };
  },
  methods: {
    setTitle(value) {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.title = value;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>

<template>
  <a href="#" @click.prevent="callback">
    <icon id="circle-check" /> {{ title }}
  </a>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function,
    label: String
  },
  data() {
    return {
      title: this.label || 'Jetzt speichern'
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/saveBtn.sass'
</style>

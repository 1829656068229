<template>
  <div class="info">
    <icon id="circle-info" /> <slot></slot>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  .info
    font-weight: bold
    color: $info
    margin: 20px 0
</style>

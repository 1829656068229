<template>
  <div class="btn-group">
    <a
      href="#"
      @click.prevent="
        weekday = index;
        setWeekday();
      "
      v-for="(day, index) in $store.state.business.unsaved.include.weekdays"
      :key="index"
      class="btn"
      :class="{ 'btn-info btn-invert': weekday === index }"
    >
      {{ $t("dates.weekdays")[index] }}
    </a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      weekday:
        this.$store.state.business.weekday ||
        Object.keys(this.$store.state.business.unsaved.include.weekdays)[0]
    };
  },
  computed: {},
  mounted() {
    this.setWeekday();
  },
  methods: {
    setWeekday() {
      this.$store.commit("business/setWeekday", this.weekday);
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
</style>

<template>
  <div>
    <assistentContact />
    <div class="calendarData">
      <div>
        <showAppointments />
      </div>
      <div>
        <autoConfirm />
      </div>
    </div>
    
    <bookingMethod />
    <verifyTime />

    <saveBtn
      v-if="checkData()"
      class="saveBtn"
      :callback="setSave"
    />
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  extends: def,
  components: {
    assistentContact() {
      return import("@/components/business/settings/contact");
    },
    autoConfirm() {
      return import("@/components/business/settings/autoConfirm");
    },
    showAppointments() {
      return import("@/components/business/settings/showAppointments");
    },
    bookingMethod() {
      return import("@/components/business/settings/bookingMethod");
    },
    verifyTime() {
      return import("@/components/business/settings/verifyTime");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    nextStep() {
      this.$router.push({ name: "form" });
    }
  }
};
</script>
<style lang="sass" scoped>
.saveBtn
  margin-top: 30px
  display: inline-block
.calendarData
  display: flex
  flex-wrap: wrap
  div
    flex: 1 auto
    margin: 5px
    margin-left: 0
</style>

<template>
  <a href="#" class="container" @click.prevent="setClick">
    <span>
      <icon id="check" class="icon" v-if="select" />
      <icon id="menu-close" class="icon no" v-else />
    </span>
    <h4>{{ title }}</h4>
  </a>
</template>
<script>
export default {
  components: {},
  props: {
    title: String,
    value: Boolean,
    callback: Function
  },
  data() {
    return {
      select: this.value
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setClick() {
      this.select = !this.select;
      this.callback(this.select);
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
.container
  display: flex
  flex-direction: row
  align-items: center
  a
    text-decoration: none
    color: $dark-grey
  .icon
    border-radius: 7px
    border: 2px solid $success
    color: $success
    font-size: 18pt
    margin-right: 5px
    &.no
      color: $danger
      border-color: $danger
</style>

<template>
  <div>
    <h1>Zusatz-Einstellungen</h1>
    <settings />
  </div>
</template>
<script>
export default {
  components: {
    settings () { return import('@/components/private/settings/index.vue') }
  },
  props: {},
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>

<template>
  <div>
    <div v-if="getState() === 'business'">
      <default v-if="$store.state.business.unsaved.type === 'datepicker' || $store.state.business.unsaved.type === 'eventpicker'"/>
      <services v-if="$store.state.business.unsaved.type === 'services'" />
    </div>
    <div v-if="getState() === 'private'">
      <private />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    default () { return import('./default') },
    services () { return import('./services') },
    private () { return import('./private') }
  },
  props: {},
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    getState () {
      return this.$store.state.type
    }
  }
}
</script>
<style lang="sass" scoped>
</style>

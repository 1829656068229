<template>
  <div>
    <div class="btn-group">
      <a href="#" @click.prevent="setSurvey('default')" class="btn btn-info" :class="{'btn-invert': survey.type === 'default'}">Standard-Frage</a>
      <a href="#" @click.prevent="setSurvey('custom')" class="btn btn-info" :class="{'btn-invert': survey.type === 'custom'}">Individuelle Fragen</a>
      <a href="#" @click.prevent="setSurvey('none')" class="btn btn-info" :class="{'btn-invert': survey.type === 'none'}">Keine Fragen</a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      survey: this.getSurvey()
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setSurvey (survey) {
      this.survey.type = survey
      this.setSave()
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.survey = this.survey;
      this.$store.commit("business/prefetch", data);
    },
    getSurvey () {
      if (typeof this.$store.state.business.unsaved.survey === 'undefined') {
        return { type: 'default' }
      } else {
        return this.$store.state.business.unsaved.survey
      }
    }
  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables'
</style>

<script>
import business from '@/components/business/title'
export default {
  extends: business,
  data() {
    return {
      title: JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved))
        .title
    };
  },
  methods: {
    setTitle(value) {
      var data = JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved));
      data.title = value;
      this.$store.commit("privateEvent/prefetch", data);
    }
  }
}
</script>

<template>
  <ol v-if="getEvents().length">
    <li v-for="(event, index) in getEvents()" :key="index">
      <a href="#" class="btn btn-danger btn-remove" v-if="getEvents().length > 1" @click.prevent="deleteEvent(index)"><icon id="bin" /></a>
      <datePicker :time="event.begin" :callback="function (d) { setDate(index, 'begin', d) }"/>
      <icon id="arrow-right" class="right" v-if="event.end != event.begin" />
      <icon id="arrow-down" class="down" v-if="event.end != event.begin"/>
      <datePicker :time="event.end" v-if="event.end != event.begin" :callback="function (d) { setDate(index, 'end', d ) }" />
      <a href="#" class="btn btn-info btn-end" v-if="event.end != event.begin" @click.prevent="setEnd(index, false)"><icon id="chevron-left" class="right" /><icon id="chevron-up" class="down" /> Terminende</a>
      <a href="#" class="btn btn-info btn-end" v-else @click.prevent="setEnd(index, true)">Terminende <icon id="chevron-right" class="right" /><icon id="chevron-down" class="down" /></a>
      <eventsCheck :index="index" />
    </li>
  </ol>
</template>
<script>
export default {
  components: {
    datePicker () { return import('@/components/default/form/datePicker') },
    eventsCheck () { return import('./eventsCheck') }
  },
  props: {},
  data () {
    return {
      events: this.getEvents()
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    getEvents () {
      return this.$store.state.business.unsaved.events
    },
    deleteEvent (index) {
      var events = JSON.parse(JSON.stringify(this.getEvents()));
      events.splice(index, 1)
      this.events = events
      this.setSave()
    },
    setDate (index, a, d) {
      var events = JSON.parse(JSON.stringify(this.getEvents()));
      events[index][a] = d
      this.events = events
      this.setSave()
    },
    setEnd (index, add) {
      var events = JSON.parse(JSON.stringify(this.getEvents()));

      var d1 = new Date()
      d1.setYear(events[index].begin.split(" ")[0].split("-")[0])
      d1.setMonth(events[index].begin.split(" ")[0].split("-")[1] * 1 - 1)
      d1.setDate(events[index].begin.split(" ")[0].split("-")[2])
      d1.setHours(events[index].begin.split(" ")[1].split(":")[0] * 1 + 1)
      d1.setMinutes(events[index].begin.split(" ")[1].split(":")[1])
      d1.setSeconds(0)
      d1.setMilliseconds(0)

      if (add) {
        events[index].end = d1.getFullYear() + '-' + this.getNullsBefore((d1.getMonth() + 1)) + '-' + this.getNullsBefore(d1.getDate()) + ' ' + this.getNullsBefore(d1.getHours()) + ':' + this.getNullsBefore(d1.getMinutes())
      } else {
        events[index].end = events[index].begin
      }
      this.events = events
      this.setSave()
    },
    getNullsBefore(index) {
      if (index * 1 < 10) {
        return "0" + index;
      } else {
        return index + "";
      }
    },
    setSave () {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.events = this.events
      this.$store.commit("business/prefetch", data);
    }
  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  ol
    margin: 0
    padding: 0
    display: flex
    align-items: center
    justify-content: flex-start
    flex-wrap: wrap
    margin-bottom: 20px
    margin-left: -10px
    margin-right: -10px
    margin-top: -10px
    li
      display: flex
      align-items: center
      justify-content: center
      flex-wrap: wrap
      padding: 10px
      border: 1px solid $success
      width: 100%
      margin: 10px
      a.btn-end
        font-size: 10pt
        margin-left: 5px
      a.btn-remove
        margin-right: 10px
      .down
        display: none
      .right
        display: flex


  @media(max-width: 500px)
    ol
      justify-content: center
      li
        flex-direction: column
        a.btn-end
          margin: 10px 0 0 0
        a.btn-remove
          margin: 0 0 10px 0
          text-align: right
          display: flex
        .down
          display: flex
        .right
          display: none
</style>

<template>
  <div>
    <location />
    <anonym />
    <comments />
    <lastMinute />
    <saveBtn
      v-if="checkData()"
      class="saveBtn"
      :callback="function() { setSave(); }"
    />
  </div>
</template>
<script>
import def from '../default/'
export default {
  extends: def,
  components: {
    location () {
      return import('./location')
    },
    lastMinute () {
      return import('./lastMinute')
    },
    anonym () {
      return import('./anonym')
    },
    comments () {
      return import('./comments')
    }
  },
  props: {},
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    nextStep () {
      this.$router.push({name: 'privatePreview'})
    }
  }
}
</script>

const empty = {
  type: "",
  styling: {
    accent: "#8dc044"
  },
  survey: {
    type: 'default',
    questions: [
      ['Wie bewerten Sie unsere Dienstleistung?']
    ]
  },
  startBtn: "jetzt Termin buchen",
  contactName: "",
  contactEmail: "",
  exclude: {
    weekdays: {},
    dates: [],
    lastminute: ""
  },
  autoconfirm: false,
  verifyTime: 2,
  include: {
    buffer: [0, 0, 0, 0, 0, 0, 0],
    weekdays: {},
    period: []
  },
  events: [],
  next_appointments: {},
  max_applicants: {},
  showAppointments: false,
  bookingMethod: "default",
  title: "",
  start: "",
  end: "",
  date: false,
  form: []
};

export default {
  namespaced: true,
  state: {
    assistent: empty,
    unsaved: empty,
    weekday: false,
    copy: {}
  },
  getters: {},
  actions: {},
  mutations: {
    update(store, data) {
      store.assistent = Object.assign(store.assistent, data);
    },
    prefetch(store, data) {
      store.unsaved = data;
    },
    setWeekday(store, data) {
      store.weekday = data;
    },
    setCopy(store, data) {
      store.copy = data;
    }
  }
};

<template>
  <div>
    <h1>Zusatz-Einstellungen</h1>
    <calendarSettings />
  </div>
</template>
<script>
export default {
  components: {
    calendarSettings() {
      return import("@/components/business/settings/index.vue");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>

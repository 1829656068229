<template>
  <div>
    <h1>Bewertung</h1>
    <info>
      Über unseren Bewertungs-Service können Kundenbewertungen zur Terminvereinbarung erfasst werden. Der Termin-Buchende kann nachfolgende Fragen beantworten.
    </info>
    <calendarSurvey />
  </div>
</template>
<script>
export default {
  components: {
    calendarSurvey() {
      return import("@/components/business/survey/index.vue");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>

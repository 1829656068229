<template>
  <div class="input">
    <inputOptional :value="value" label="Frage" :callback="setValue" />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      value: 'Wie zufrieden sind Sie mit der Online-Termin-Buchung über Bookingflix.com ?'
    }
  },
  methods: {
    setValue () {
    }
  }
}
</script>
<style lang="sass" scoped>
  .input
    pointer-events: none
    cursor: default
</style>

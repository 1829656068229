<template>
  <div>
    <input type="text" v-model="data.label" placeholder="Überschrift ...">
    <b>Auswahlmöglichkeiten</b>
    <ol>
      <li v-for="(value, index) in data.values" :key="index">
        <input type="text" v-model="value.label" @keydown="function(v) { setValue(index, value.label) }">
        <a href="#" class="btn btn-info" @click.prevent="addField(index)"><icon id="plus" /></a>
        &nbsp;
        <a href="#" v-if="data.values.length > 1" class="btn btn-danger" @click.prevent="removeField(index)"><icon id="minus" /></a>
      </li>
    </ol>
    <textarea type="text" v-model="data.description" placeholder="Beschreibung (optional) ..." />
    <required />
    <columnWidth />
  </div>
</template>
<script>
export default {
  components: {
    columnWidth () {
      return import('./width.vue')
    },
    required () {
      return import('./required.vue')
    }
  },
  props: {
    data: Object
  },
  watch: {
    'data.description' () { this.setSave() },
    'data.label' () { this.setSave() }
  },
  data () {
    return {

    }
  },
  methods: {
    addField (index) {
      var copy = JSON.parse(JSON.stringify(this.data.values))
      this.data.values.splice(index + 1, 0, copy)
      this.setSave()
    },
    removeField (index) {
      this.data.values.splice(index, 1)
      this.setSave()
    },
    setValue (index, value) {
      this.data.values[index] = {value: value, label: value}
      this.setSave()
    },
    setSave () {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.form[this.$store.state.form.edit] = this.data;
      this.$store.commit("business/prefetch", data);
    }
  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  input, textarea
    width: 100%
    padding: 10px
    box-sizing: border-box
    border: 1px solid $success
    &:focus
      outline: none
  b
    margin-top: 10px
    display: block
  textarea
    margin-top: 10px
    height: 200px
  ol
    display: flex
    padding: 0
    margin: 0
    flex-direction: column
    flex-wrap: wrap
    font-weight: bold
    padding-left: 15px
    li
      padding-left: 10px
      margin: 5px 0
      .btn
        font-size: 8pt
        padding: 3px
        width: 20px
        height: 20px
</style>

<template>
  <ul>
    <li>
      <router-link
        to="/format"
        :class="{ active: $store.state.type === 'business' }"
        @click.native="$store.commit('setType', 'business')"
        >Geschäftliche Termine</router-link
      >
    </li>
    <li>
      <router-link
        to="/private/styling"
        :class="{ active: $store.state.type === 'private' }"
        @click.native="$store.commit('setType', 'private')"
        >Private Termine</router-link
      >
    </li>
  </ul>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped>
ul
  padding: 0
  margin: 0
  list-style: none
  display: flex
  margin-bottom: 20px
  flex-wrap: wrap
  li
    margin-right: 8px
    margin-bottom: 15px
    a
      word-break: keep-all
      white-space: nowrap
      word-wrap: none
      font-size: 10pt
      text-transform: uppercase
      padding: 5px 12px
      border-radius: 5px
      color: darkblue
      border: 1px solid darkblue
      text-decoration: none
      background-color: transparent
      &.active
        color: white
        background-color: darkblue
</style>

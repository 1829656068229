<template>
  <div class="container">
    <div class="item register">
      <form>
        <saveBtn label="Kalender erstellen" />
      </form>
      <info class="info">Sie erhalten alle weiteren Informationen an Ihre Email {{ this.$store.state.privateEvent.unsaved.email }} gesendet</info>
    </div>
    <div class="item preview">
      <h3>Vorschau des Terminkalenders</h3>
      <hit-calendar id="1" :data="data" callback=""/>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data () {
    return {
      data: btoa(JSON.stringify({
        data: this.$store.state.privateEvent.unsaved
      }))
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  .container
    display: flex
    margin-left: -10px
    margin-right: -10px
    h3
      margin-top: 0
      text-align: center
    .item
      margin: 10px
    .item.register
      width: 30%
      border: 2px dotted $dark-grey
      border-radius: 10px
      box-sizing: border-box
      flex-direction: column
      display: flex
      justify-content: center
      align-items: center
      padding: 20px
      text-align: center
      .info
        font-size: 10pt
        margin-bottom: 0
    .item.preview
      flex: 1

  @media(max-width: 500px)
    .container
      flex-direction: column-reverse
      margin: 0
      .item.register, .item.preview
        width: 100%
        flex: 1
</style>

<template>
  <div>
    <h4>
      Wie lange dauert ein Termin am
      {{ $t("dates.weekdays")[$store.state.business.weekday] }}?
    </h4>
    <div class="range">
      <a href="#" @click.prevent="changeDuration(-1)" class="btn btn-icon">
        <icon id="chevron-left" />
      </a>
      <input
        class="btn"
        :value="values[appointmentDuration]"
        type="text"
        disabled
        step="1"
        max="20"
      />
      <a href="#" @click.prevent="changeDuration(1)" class="btn btn-icon">
        <icon id="chevron-right" />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      appointmentDuration: this.$store.state.business.unsaved.include.weekdays[
        this.$store.state.business.weekday
      ][2],
      values: {
        "15": "15 Minuten",
        "30": "30 Minuten",
        "60": "1 Stunde",
        "120": "2 Stunden",
        "180": "3 Stunden",
        "240": "4 Stunden"
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.include.weekdays[
        this.$store.state.business.weekday
      ][2] = this.appointmentDuration;
      this.$store.commit("business/prefetch", data);
    },
    changeDuration(add) {
      var keys = Object.keys(this.values);
      var index = keys.indexOf(this.appointmentDuration + "");
      index = index + add;

      if (index < 0) {
        index = keys.length - 1;
      }
      if (index === keys.length) {
        index = 0;
      }

      this.appointmentDuration = keys[index];
      this.setSave();
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'
@import '@/assets/sass/range'
.range
  input
    font-size: 20pt
</style>

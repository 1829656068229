<template>
  <div class="container">
    <a
      href="#"
      class="item"
      :class="{ active: format == item.type }"
      v-for="(item, index) in data"
      :key="index"
      @click.prevent="setFormat(item)"
    >
      <div class="icon"><icon :id="item.icon" /></div>
      <div class="header">{{ item.header }}</div>
      <div class="description">{{ item.description }}</div>
    </a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      format: this.$store.state.business.assistent.type,
      data: [
        {
          icon: "clock",
          header: "Feste Öffnungszeiten",
          description:
            "Für Ladengeschäfte und Dienstleister: z. B. Friseure, Berater, Foto-Studios",
          type: "datepicker"
        },
        {
          icon: "calendar",
          header: "Konkretes Event",
          description:
            "Für fixe Termine: z. B. Webinare, Meetings, Veranstaltungen",
          type: "eventpicker"
        },
        {
          icon: "comments",
          header: "Angebote / Dienstleistungen",
          description:
            "Für Dienstleistungen mit unterschiedlicher Termindauer: z. B. Servicetelefonate, Frieseurleistungen",
          type: "services"
        }
      ]
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setFormat(item) {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.type = item.type

      this.$store.commit("business/prefetch", data);
      this.$store.commit("business/update", { type: item.type });

      if (item.type === 'services') {
        this.$router.push({ name: "preview" });
      } else {
        this.$router.push({ name: "styling" });
      }
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/format.sass'
</style>

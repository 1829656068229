<template>
  <div>
    <label>{{ data.label }}</label>
    <ul>
      <li v-for="value in data.values" :key="value.value"><a href="#" @click.prevent="setClick(value.value)"><icon class="icon" id="square-empty" v-if="clicked.indexOf(value.value) === -1" /> <icon class="icon" v-else id="square-check" /> {{ value.label }}</a></li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object
  },
  data () {
    return {
      clicked: []
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setClick (value) {
      var index = this.clicked.indexOf(value)
      if (index === -1){
        this.clicked.push(value)
      } else {
        this.clicked.splice(index, 1)
      }
    }
  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/sass/variables.sass'
  label
    font-weight: bold
  ul
    margin: 0
    padding: 0
    list-style: none
    display: flex
    flex-direction: column
    margin-top: 10px
    li
      a
        text-decoration: none
        color: $dark-grey
        .icon
          color: $success
</style>
